import React, { useEffect } from "react";
import { Helmet } from "react-helmet";

import Avatar from "../images/avatar_small.png";
import "../styles/font-awesome.min.css";

// markup
const IndexPage = () => {
  useEffect(() => {
    var user = "malte",
      domain = "schiebelmann.de",
      element = document.getElementById("email");
    element.href = "mailto:" + user + "@" + domain;
  }, []);

  return (
    <div id="wrapper">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Malte Schiebelmann | Product Guy. Berlin. Zurich.</title>
        <meta name="viewport" content="width=device-width, initial-scale=1" />
      </Helmet>
      <section id="main">
        <header>
          <span className="avatar">
            <img src={Avatar} alt="Avatar" />
          </span>
          <h1>Malte Schiebelmann</h1>
          <p>
            Head of Product,{" "}
            <a href="https://zattoo.com/" target="_blank" rel="noreferrer">
              Zattoo
            </a>
            <br />
            Previously Chief Product Officer (CPO),{" "}
            <a href="http://doodle.com" target="_blank" rel="noreferrer">
              Doodle
            </a>
          </p>
        </header>
        <footer>
          <ul className="icons">
            <li>
              <a
                href="https://www.linkedin.com/in/mschiebelmann/"
                className="fa-linkedin-square"
                target="_blank"
                rel="noreferrer"
              >
                LinkedIn
              </a>
            </li>
            <li>
              <a
                href="http://www.github.com/IAmMalte"
                className="fa-github"
                target="_blank"
                rel="noreferrer"
              >
                GitHub
              </a>
            </li>
            <li>
              <a
                href="http://twitter.com/IAmMalte"
                className="fa-twitter"
                target="_blank"
                rel="noreferrer"
              >
                Twitter
              </a>
            </li>
            <li>
              <a id="email" href="/" className="fa-envelope-o">
                Email
              </a>
            </li>
          </ul>
        </footer>
      </section>

      <footer id="footer">
        <ul className="copyright">
          <li>&copy; Malte Schiebelmann</li>
          <li>
            Design: <a href="http://html5up.net">HTML5 UP</a>
          </li>
        </ul>
      </footer>
    </div>
  );
};

export default IndexPage;
